import React, { useRef, useState, useEffect } from 'react';
import { OpenCvProvider } from 'opencv-react';

import colorPicker from '../../assets/image/color picker.jpeg'

import Range from '../slider/slider';

import './segment-container.css'

const SegmentContainer = ({ file }) => {
    const input_img = useRef(null);
    const output_img = useRef(null);

    const [hue, setHue] = useState([0, 50]);
    const [saturation, setSaturation] = useState([0, 50]);
    const [value, setValue] = useState([0, 50]);
    const [cv, setCV] = useState(null);
    const [imgWid, setImgWid] = useState(0);
    const [imgHeg, setImgHeg] = useState(0);

    const onLoaded = (cv) => {
        setCV(cv);
    }

    useEffect(() => {
        if (cv) {
            let mat = cv.imread(input_img.current);
            var size = new cv.Size(40, 40);
            var hsv = new cv.Mat();
            cv.cvtColor(mat, hsv, cv.COLOR_RGB2HSV, 0);

            let lowScalar = new cv.Scalar(hue[0], saturation[0], value[0]);
            let highScalar = new cv.Scalar(hue[1], saturation[1], value[1]);

            var low = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), lowScalar);
            var high = new cv.Mat(hsv.rows, hsv.cols, hsv.type(), highScalar);

            cv.inRange(hsv, low, high, hsv);

            var img = new cv.Mat(size, cv.CV_32FC3);
            cv.resize(mat, img, size, 0, 0, cv.INTER_AREA);

            cv.bitwise_and(mat, mat, img, hsv);
            cv.imshow(output_img.current, img);
            mat.delete();
            hsv.delete();
        }
        console.log('execute');
    }, [hue, saturation, value, cv, imgHeg, imgWid])

    const onImgLoad = ({ target: img }) => {
        console.log("image: ", img.offsetHeight, img.offsetWidth);
        setImgHeg(img.offsetHeight);
        setImgWid(img.offsetWidth);
    }

    return (
        <OpenCvProvider onLoad={onLoaded}>
            <div className="container">
                <div className="image_container">
                    <img src={file} ref={input_img} alt="upload" onLoad={onImgLoad} className="selected-image"/>
                </div>

                <div className="image_container">
                    <canvas id="output" ref={output_img}></canvas>
                </div>
            </div>
            <div className="segment-slider">
                <div className="row color-picker-outer">
                    <div className="row_resizable">
                        <div className="column left">
                        </div>
                        <div className="column middle">
                            <img src={colorPicker} alt='color picker' className='color_picker' />
                        </div>
                        <div className="column right">
                        </div>
                    </div>
                </div>
                <Range onChangeCommitted={(event, value) => setHue(value)} max={179} label="Hue" />
                <Range onChangeCommitted={(event, value) => setSaturation(value)} max={255} label="Saturation" />
                <Range onChangeCommitted={(event, value) => setValue(value)} max={255} label="Value" />
            </div>
        </OpenCvProvider>
    )
}

export default SegmentContainer;