import React, { useEffect } from 'react';
import './App.scss';
import Segmentation from './screens/segmentation/segmentation';

const App = () => {

  return (
    <div className="App">
      <Segmentation />
    </div>
  );
}

export default App;
