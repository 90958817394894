import React, { useState } from 'react';

import Home from '../home/home';
import Loading from '../loading/loading';
import { fileResizer } from '../../utils/file-resizer';

import './segmentation.css'

const Segmentation = () => {

    const [file, setFile] = useState(null);
    const [isImageSelected, setImageSelected] = useState(false);

    const getSelectedFile = async (file) => {
        if (file) {
            const image = await fileResizer(file, 300, 300, "PNG");
            console.log("image: ", image);
            setFile(URL.createObjectURL(image));
            setImageSelected(true);
        }
    }

    return (
        <div>
            {
                isImageSelected ? <Loading file={file}/> : <Home selectedFile={getSelectedFile}/>
            }
        </div>
    )
}

export default Segmentation;