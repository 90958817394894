// import React, { Component } from 'react';

// import Button from '../../components/button/button';
// import Logo from '../../components/logo/logo';

// import './home.scss';
// class Home extends Component {


//     // Away we go.
//     render() {
//       return (
//         <div>
//           <Logo />
//             <div>
//                 <Button onChange={(event) => {
//                     const file = event.target.files[0];
//                     this.props.selectedFile(file)
//                 }} />
//             </div>
//         </div>
//       );
//     }
// }

// export default Home;

import React from 'react';
import './home.scss';
import Button from '../../components/button/button';
import Logo from '../../components/logo/logo';
// import VerticalMarquee from './components/vertical-marquee/vertical-marquee';

const Home = ({ selectedFile }) => {

  const handleChange = event => {
    const fileUpload = event.target.files[0];
    selectedFile(fileUpload)
}

  return (
    <div>
      <div className="header">
        <header>
          <Logo />
        </header>
      </div>
      <div className="body">
        <Button handleChange={handleChange}/>
      </div>
    </div>
  );
}

export default Home;
