import React, { useState, useEffect } from 'react';

import SegmentContainer from '../../components/segment-container/segment-container';
import Loader from '../../components/loader/loader';

export const Loading = ({ file }) => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])

    return (
        <>
            {isLoading ? <Loader /> : <SegmentContainer file={file}/>}
        </>
    )
}

export default Loading;
