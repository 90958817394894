import React from 'react';

import './button.css'

const Button = ({ handleChange }) => {

    return (
        <div className="wrapper">
          <input name="input" id="file" accept="image/*" onChange={handleChange} type="file"/>
            <label className="btn6" htmlFor="file">Upload an image</label>
        </div>
    )
}

export default Button;
