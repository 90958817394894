/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import './logo.scss';

const Logo = () => {

    return (
        <div>
            <h1 className="header-logo">
                <a href="https://www.cliqueraft.com/" className="link-hover" target="_blank">
                    Cliqueraft.
                </a>
            </h1>
        </div>
    )
}

export default Logo
