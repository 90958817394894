import React, { memo, useState, useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import './slider.css';

const muiTheme = createMuiTheme({
  overrides:{
    MuiSlider: {
      thumb:{
      color: "black",
      },
      track: {
        color: 'black'
      },
      rail: {
        color: 'black'
      }
    }
}
});

const Range = ({onChange, max, label, onChangeCommitted }) => {

  const [value, setValue] = useState([0,50]);

  return (
    <>
      <div className="leftbox">
        <span className="label">{label}</span>
      </div>
      <div className="middlebox">
      <ThemeProvider theme={muiTheme}>
        <Slider
          value={value}
          onChange={(e, v) => setValue(v)}
          onChangeCommitted={onChangeCommitted}
          valueLabelDisplay="auto"
          max={max}
          color="primary"
          style={{ primary: 'black' }}
        />
        </ThemeProvider>
      </div>
      <div className="rightbox">
        <span className="value"> {value[0]}, {value[1]} </span>
      </div>
    </>
  )

};


export default memo(Range);