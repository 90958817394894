import Resizer from 'react-image-file-resizer';

export const fileResizer = (file, width, height, format) => new Promise((resolve) => {
    Resizer.imageFileResizer(
        file, // file you want to resize
        300, // maximum width of file
        300, // maximum height of file
        format, // what compress format do you need file for instance :- "JPG", "PNG" or "WEBP"
        1000, // what should be the quality of the file after resize
        0, // rotation 
        (uri) => resolve(uri), // callBack function of the resized new image URI
        "file", // the output type of the resized new image
    )
})