import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import './loader.scss'

const styles = {
    root: {
      flexGrow: 1
    },
    colorPrimary: {
      background: 'green'
    }
  };

  const CustomLinearProgress = withStyles(() => ({
    bar: {
        backgroundColor: '#000000'
    }
  }))(LinearProgress)

export const Loader = ({ file }) => {
    
    return (
        <div >
            <CustomLinearProgress />
            <div className="center">
                <p className="loading-text"> Loading libraries... </p>
            </div>
        </div>
    )
}

export default withStyles(styles)(Loader);
